<template>
    <div :class="[ mobileLogin ? 'chat-page-mobile': 'chat_page' ]">
        <div class="customer-container">
            <el-popover popper-class="customer-container" placement="left" trigger="click">
                <img class="wechat-qrcode" src="../assets/images/wechat_qrcode.png" />
                <div slot="reference" class="customer">
                    <img class="customer-img" slot="reference" src="../assets/images/customer.png" />
                    <span class="customer-text">扫码添加客服</span>
                    <span class="customer-text">首次关注有好礼</span>
                </div>
            </el-popover>
        </div>

        <div v-if="showMask" class="mask"></div>
        <!-- 折叠/展开侧边栏按钮 -->
        <div v-if="!mobileLogin" class="chat_sidecar_arrow_container" @click="showSidecar">
            <img class="chat_sidecar_arrow" :class="[displaySidecar ? 'animateRotateLeft' : 'animateRotateRight']"
                src="../assets/images/arrow.png" />
        </div>

        <div v-if="showTopSidecarDiv && !mobileLogin" class="chat-top-sidecar" :class="[showTopSidecar ? 'animateIn' : 'animateOut']">
            <div class="close-sidecar" @click="handleCloseTopSidecar">
                <i class="el-icon-close"></i>
            </div>
            <div class="chat_sidecar_title">
                <div class="image-wrapper">
                    <img class="logo" referrerpolicy="no-referrer" src="../assets/images/etai_logo.png" />
                </div>
                <span class="title_text">ETAI-GPT</span>
                <div class="pencil_bg_img" @click="displayNewChatDialog = true">
                    <img class="pencil_img" referrerpolicy="no-referrer" src="../assets/images/pencil.png" />
                </div>
            </div>
            <div class="chat_sidecar_scroll_container">
                <!-- <div class="history-list-item-wrapper" v-for="item in chatConversations"> -->
                <draggable class="history_list_item_draggable" v-model="chatConversations" @end="handleChatConversationsDragEnd">
                    <div class="history_list_item" @click="handleSelectConversation(item.uid)"
                        v-for="item in chatConversations">
                        <span class="history_list_item_content">{{ item.title }}</span>
                        <div class="dots-container" @click.stop>
                            <el-dropdown size="medium" placement="bottom" trigger="click"
                                @command="handleDropdownMenuItemClick">
                                <img class="history_list_item_img" referrerpolicy="no-referrer"
                                    src="../assets/images/dots.png" />

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-info"
                                        :command="'handleDetailConversation:' + item.uid">详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete-solid"
                                        :command="'handleDeleteConversation:' + item.uid">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>

                    </div>
                </draggable>
                <!-- </div> -->
            </div>
            <!-- <div class="chat_sidecar_plans" :class="[!displayMenu ? 'animateDisplayPlans' : 'animateTopPlans']"
                @click="showPlansTab">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">充值</span>
            </div> -->
            <div class="chat_sidecar_plans" :class="[displayMenu ? 'animateDisplayQuit' : 'animateHideSettings']"
                @click="logout">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">退出</span>
            </div>
            <div v-show="false" class="chat_sidecar_settings"
                :class="[displayMenu ? 'animateDisplaySettings' : 'animateHideSettings']" @click="showSettingsTab">
                <i class="el-icon-setting"></i>
                <span>Settings</span>
            </div>
            <div class="chat-sidecar-user-card">
                <div class="membership-container">
                    <img src="../assets/images/vip.png" />
                    <span>{{ userInfo.monthlyInfo.userTitle ? userInfo.monthlyInfo.userTitle : '普通用户' }}</span>
                </div>
                <div class="chat_sidecar_user" @click="handleDisplayMenu">
                    <div class="chat_sidecar_user_icon">
                        <img class="user_icon" :src="userInfo.img" />
                    </div>
                    <div class="chat-sidecar-user-card-content">
                        <span class="user_name_text">{{ userInfo.name }}</span>
                        <span class="time-expired" v-show="userInfo.monthlyInfo.isExpired">已过期</span>
                        <span class="user-card-membership-expiration" v-show="!userInfo.monthlyInfo.isExpired">有效期至: {{
                        userInfo.monthlyInfo.endTime ? userInfo.monthlyInfo.endTime : '永久' }}</span>
                    </div>
                </div>
                <div class="user-monthly-card">
                    <span class="user-coins-prefix">ETAI币:</span>
                    <span class="user-coins">{{ userInfo.coins }}</span>
                </div>
                <div class="pay-btn" @click="showPlansTab">
                    <span class="pay-btn-text">充值</span>
                </div>
            </div>
        </div>

        <!-- 侧边栏 -->
        <div v-if="!mobileLogin" class="chat_sidecar" :class="[displaySidecar ? 'animateIn' : 'animateOut']">
            <div class="chat_sidecar_title">
                <div class="image-wrapper">
                    <img class="logo" referrerpolicy="no-referrer" src="../assets/images/etai_logo.png" />
                </div>
                <span class="title_text">ETAI-GPT</span>
                <div class="pencil_bg_img" @click="displayNewChatDialog = true">
                    <img class="pencil_img" referrerpolicy="no-referrer" src="../assets/images/pencil.png" />
                </div>
            </div>
            <div class="chat_sidecar_scroll_container">
                <!-- <div class="history-list-item-wrapper" v-for="item in chatConversations"> -->
                <draggable class="history_list_item_draggable" v-model="chatConversations" @end="handleChatConversationsDragEnd">
                    <div class="history_list_item" @click="handleSelectConversation(item.uid)"
                        v-for="item in chatConversations">
                        <span class="history_list_item_content">{{ item.title }}</span>
                        <div class="dots-container" @click.stop>
                            <el-dropdown size="medium" placement="bottom" trigger="click"
                                @command="handleDropdownMenuItemClick">
                                <img class="history_list_item_img" referrerpolicy="no-referrer"
                                    src="../assets/images/dots.png" />
    
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-info"
                                        :command="'handleDetailConversation:' + item.uid">详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete-solid"
                                        :command="'handleDeleteConversation:' + item.uid">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
    
                    </div>
                </draggable>
                <!-- </div> -->
            </div>
            <!-- <div class="chat_sidecar_plans" :class="[!displayMenu ? 'animateDisplayPlans' : 'animateTopPlans']"
                @click="showPlansTab">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">充值</span>
            </div> -->
            <div class="chat_sidecar_plans" :class="[displayMenu ? 'animateDisplayQuit' : 'animateHideSettings']"
                @click="logout">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">退出</span>
            </div>
            <div v-show="false" class="chat_sidecar_settings"
                :class="[displayMenu ? 'animateDisplaySettings' : 'animateHideSettings']" @click="showSettingsTab">
                <i class="el-icon-setting"></i>
                <span>Settings</span>
            </div>
            <div class="chat-sidecar-user-card">
                <div class="membership-container">
                    <img src="../assets/images/vip.png" />
                    <span>{{ userInfo.monthlyInfo.userTitle ? userInfo.monthlyInfo.userTitle : '普通用户' }}</span>
                </div>
                <div class="chat_sidecar_user" @click="handleDisplayMenu">
                    <div class="chat_sidecar_user_icon">
                        <img class="user_icon" :src="userInfo.img" />
                    </div>
                    <div class="chat-sidecar-user-card-content">
                        <span class="user_name_text">{{ userInfo.name }}</span>
                        <span class="time-expired" v-show="userInfo.monthlyInfo.isExpired">已过期</span>
                        <span class="user-card-membership-expiration" v-show="!userInfo.monthlyInfo.isExpired">有效期至: {{
                        userInfo.monthlyInfo.endTime ? userInfo.monthlyInfo.endTime : '永久' }}</span>
                    </div>
                </div>
                <div class="user-monthly-card">
                    <span class="user-coins-prefix">ETAI币:</span>
                    <span class="user-coins">{{ userInfo.coins }}</span>
                </div>
                <div class="pay-btn" @click="showPlansTab">
                    <span class="pay-btn-text">充值</span>
                </div>
            </div>
        </div>

        <div v-if="mobileLogin" class="chat-top-sidecar" :class="[showTopSidecar ? 'animateIn' : 'animateOut']">
            <div class="close-sidecar" @click="handleCloseTopSidecar">
                <i class="el-icon-close"></i>
            </div>
            <div class="chat_sidecar_title">
                <div class="image-wrapper">
                    <img class="logo" referrerpolicy="no-referrer" src="../assets/images/etai_logo.png" />
                </div>
                <span class="title_text">ETAI-GPT</span>
                <div class="pencil_bg_img" @click="displayNewChatDialog = true; showTopSidecar = false">
                    <img class="pencil_img" referrerpolicy="no-referrer" src="../assets/images/pencil.png" />
                </div>
            </div>
            <div class="chat_sidecar_scroll_container">
                <!-- <div class="history-list-item-wrapper" v-for="item in chatConversations"> -->
                <draggable class="history_list_item_draggable" v-model="chatConversations" @end="handleChatConversationsDragEnd">
                    <div class="history_list_item" @click=" handleMobileSelectConversation(item.uid)"
                        v-for="item in chatConversations">
                        <span class="history_list_item_content">{{ item.title }}</span>
                        <div class="dots-container" @click.stop>
                            <el-dropdown size="medium" placement="bottom" trigger="click"
                                @command="handleDropdownMenuItemClick">
                                <img class="history_list_item_img" referrerpolicy="no-referrer"
                                    src="../assets/images/dots.png" />

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-info"
                                        :command="'handleDetailConversation:' + item.uid">详情</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete-solid"
                                        :command="'handleDeleteConversation:' + item.uid">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>

                    </div>
                </draggable>
                <!-- </div> -->
            </div>
            <!-- <div class="chat_sidecar_plans" :class="[!displayMenu ? 'animateDisplayPlans' : 'animateTopPlans']"
                @click="showPlansTab">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">充值</span>
            </div> -->
            <div class="chat_sidecar_plans" :class="[displayMenu ? 'animateDisplayQuit' : 'animateHideSettings']"
                @click="logout">
                <i class="el-icon-shopping-bag-2 pay-content"></i>
                <span class="pay-content">退出</span>
            </div>
            <div v-show="false" class="chat_sidecar_settings"
                :class="[displayMenu ? 'animateDisplaySettings' : 'animateHideSettings']" @click="showSettingsTab">
                <i class="el-icon-setting"></i>
                <span>Settings</span>
            </div>
            <div class="chat-sidecar-user-card">
                <div class="membership-container">
                    <img src="../assets/images/vip.png" />
                    <span>{{ userInfo.monthlyInfo.userTitle ? userInfo.monthlyInfo.userTitle : '普通用户' }}</span>
                </div>
                <div class="chat_sidecar_user" @click="handleDisplayMenu">
                    <div class="chat_sidecar_user_icon">
                        <img class="user_icon" :src="userInfo.img" />
                    </div>
                    <div class="chat-sidecar-user-card-content">
                        <span class="user_name_text">{{ userInfo.name }}</span>
                        <span class="time-expired" v-show="userInfo.monthlyInfo.isExpired">已过期</span>
                        <span class="user-card-membership-expiration" v-show="!userInfo.monthlyInfo.isExpired">有效期至: {{
                        userInfo.monthlyInfo.endTime ? userInfo.monthlyInfo.endTime : '永久' }}</span>
                    </div>
                </div>
                <div class="user-monthly-card">
                    <span class="user-coins-prefix">ETAI币:</span>
                    <span class="user-coins">{{ userInfo.coins }}</span>
                </div>
                <div class="pay-btn" @click="showPlansTabAndCloseSidecar">
                    <span class="pay-btn-text">充值</span>
                </div>
            </div>
        </div>

        <!-- 对话框 -->
        <!-- <div class="chat_area_container" :class="[displaySidecar ? 'animateChatAreaCollapse' : 'animateChatAreaExpand']"> -->
        <div v-show="!mobileLogin" class="chat_area_container animate__animated animate__slideInRight"
            :class="[!mobileLogin && displaySidecar ? animateChatAreaCollapseClass : 'animateChatAreaExpand']">
            <div class="chat-area-title-sidecar" @click="handleShowTopSidecar">
                <img class="chat-area-title-sidecar-icon" src="../assets/images/navicon-round.png" />
            </div>
            <div class="chat-area-title">
                <span>{{ selectedConversation == undefined ? '' : selectedConversation.title }}</span>
                <span>{{ titleSpace }}</span>
                <span>({{ selectedConversation == undefined ? '' : selectedConversation.gptModel }})</span>
            </div>

            <div id="chat-area" ref="chatArea" class="chat_area" @scroll="handleScroll">
                <div v-for="item in chatRecords">
                    <ChatUser ref="chatuserContainer" v-if="item.role == 'user'" class="user-content"
                        :content="item.message" :username="userInfo.name" :user-img="userInfo.img">
                    </ChatUser>
                    <ChatGPT ref="chatgptContainer" v-if="item.role == 'assistant'" class="etai-content"
                        :content="item.message"></ChatGPT>
                </div>
            </div>

            <!-- 输入框 -->
            <div class="chat_input">
                <el-input class="chat-el-input" type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" resize="none"
                    v-model="message" placeholder="发送消息给 ETAI GPT..." :disabled="disabledInput"
                    @keydown.native="handleKeydown" @compositionstart.native="handleCompositionStart"
                    @compositionend.native="handleCompositionEnd">
                </el-input>
                <i @click="createChatRecord" class="chat-el-button el-icon-s-promotion" />
                <div class="chat_context_switch">
                    <span class="chat_context_switch_text">是否开启上下文</span>
                    <el-switch
                        v-model="enableContext"
                        active-color="#13ce66">
                    </el-switch>
                </div>
            </div>
        </div>

        <!-- 手机页面对话框 -->
        <div v-if="mobileLogin" class="chat-mobile-area-container">
            <div class="chat-area-title">
                <div class="chat-area-title-sidecar" @click="handleShowMobileSidecar">
                    <img class="chat-area-title-sidecar-icon" src="../assets/images/navicon-round.png" />
                </div>
                <div class="chat-area-title-text">
                    <span>{{ selectedConversation == undefined ? '' : selectedConversation.title }}</span>
                    <span>{{ titleSpace }}</span>
                    <span>({{ selectedConversation == undefined ? '' : selectedConversation.gptModel }})</span>
                </div>
            </div>

            <div id="chat-area-mobile" ref="chatAreaMobile" class="chat_area">
                <div v-for="item in chatRecords">
                    <ChatUser ref="chatuserContainer" v-if="item.role == 'user'" class="user-content"
                        :content="item.message" :username="userInfo.name" :user-img="userInfo.img" :mobile-login="mobileLogin">
                    </ChatUser>
                    <ChatGPT ref="chatgptContainer" v-if="item.role == 'assistant'" class="etai-content"
                        :content="item.message" :mobile-login="mobileLogin"></ChatGPT>
                </div>
            </div>

            <!-- 输入框 -->
            <div class="chat_input">
                <div class="chat_context_switch">
                    <span class="chat_context_switch_text">是否开启上下文</span>
                    <el-switch
                        v-model="enableContext"
                        active-color="#13ce66">
                    </el-switch>
                </div>
                <el-input class="chat-el-input" type="textarea" :autosize="{ minRows: 1, maxRows: 3 }" resize="none"
                    v-model="message" placeholder="发送消息给 ETAI GPT..." :disabled="disabledInput"
                    @keydown.native="handleKeydown" @compositionstart.native="handleCompositionStart"
                    @compositionend.native="handleCompositionEnd">
                </el-input>
                <i @click="createChatRecord" class="chat-el-button el-icon-s-promotion" />
            </div>
        </div>

        <el-dialog top="1vh" v-loading="showWatingCreateOrder" :title="payTitle" custom-class="plan-container"
            :visible.sync="planDialogVisible" center :close-on-press-escape="false">
            <Pay v-if="planDialogVisible" @selectedPlan="handleSelectedPlan" :show-plan="showPlan"
                :showPayCodeImg="showPayCodeImg" :pay-code-img="payCodeImg" :show-pay-result="showPayResult"
                :monthly-info="userInfo.monthlyInfo" :pay-result="payResult"
                :show-upgrade-order-tips="showUpgradeOrderTips" :diff-price="diffPrice"
                @closePayCodeImg="handleClosePayCodeImg" @selectedMenu="handleSelectedMenu"
                @closePayResult="handleClosePayResult" @closeUpgradeOrderTips="handleCloseUpgradeOrderTips"
                @confirmUpgradeOrder="handleConfirmUpgradeOrder">
            </Pay>
            <div slot="footer" class="dialog-footer">
                <el-button @click="planDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handlePayOrder">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog top="5vh" title="充值页面" :visible.sync="showMobilePayPage" custom-class="pay-mobile-dialog">
            <PayMobile v-if="showMobilePayPage" @selectedPlan="handleSelectedPlan" :show-plan="showPlan"
                :showPayCodeImg="showPayCodeImg" :pay-code-img="payCodeImg" :show-pay-result="showPayResult"
                :monthly-info="userInfo.monthlyInfo" :pay-result="payResult"
                :show-upgrade-order-tips="showUpgradeOrderTips" :diff-price="diffPrice"
                @closePayCodeImg="handleClosePayCodeImg" @selectedMenu="handleSelectedMenu"
                @closePayResult="handleClosePayResult" @closeUpgradeOrderTips="handleCloseUpgradeOrderTips"
                @confirmUpgradeOrder="handleConfirmUpgradeOrder"></PayMobile>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showMobilePayPage = false">取 消</el-button>
                <el-button type="primary" @click="handlePayOrder">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="创建新对话" custom-class="new-chat-dialog" :visible.sync="displayNewChatDialog">
            <NewChat v-if="displayNewChatDialog" @submit="createConversation"></NewChat>
        </el-dialog>

        <el-dialog title="对话详情" custom-class="new-chat-dialog" :visible.sync="displayConversationDetail">
            <NewChat v-if="displayConversationDetail" :params="detailConversation" :showDetail="true"
                @submit="createConversation"></NewChat>
        </el-dialog>

        <div class="beian">
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023023096号-1</a>
        </div>
    </div>
</template>

<script>
import {
    _getChatRecords,
    _getChatConversations,
    _createChatConversation,
    _deleteChatConversation,
    _getUserCoinsBalance,
    _pay,
    _getOrderStatus,
    _logout,
    _getUserInfo,
    _preUpgradeOrder,
    _upgradeOrder,
    _getJsapiConfig,
    _updateChatConversationsSortKey,
    _updateChatConversationsInfo
} from '@/services/chatgpt/chatgpt'

import ChatGPT from './ChatGPT.vue'
import ChatUser from './ChatUser.vue'
import Pay from './Pay.vue'
import NewChat from './NewChat.vue'
import PayMobile from './PayMobile.vue'
import { MessageBox } from 'element-ui';
import Drag from './js/move.js';
import wx from 'weixin-js-sdk';
import draggable from 'vuedraggable';


export default {
    name: "Chat",
    components: {
        ChatGPT,
        ChatUser,
        Pay,
        NewChat,
        PayMobile,
        draggable,
    },
    mounted() {
        this.init();
        // this.initScroll();

        window.onresize = () => {
            return (() => {
                let screenWidth = document.body.clientWidth
                if (screenWidth < 1228) {
                    this.animateChatAreaCollapseClass = 'animateChatAreaExpand'
                    this.showTopSidecarDiv = true
                } else {
                    this.animateChatAreaCollapseClass = 'animateChatAreaCollapse'
                    this.showTopSidecarDiv = false
                }
            })()
        }

        // window.addEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            isLoading: false,
            isScrollTop: false,
            mobileLogin: false,
            showMask: false,
            showTopSidecar: false,
            showMobileMask: false,
            showMobileSidecar: false,
            showTopSidecarDiv: false,
            animateChatAreaCollapseClass: 'animateChatAreaCollapse',
            userInfo: {
                name: 'EarthTail AI',
                gender: 0,
                img: require('../assets/images/user_default.png'),
                coins: 0,
                monthlyInfo: {
                    startTime: '',
                    endTime: '',
                    planLevel: '',
                    userTitle: '',
                    planDisplayName: '',
                    supportModels: [],
                    isExpired: false,
                    planType: '',
                    planUid: '',
                }
            },
            chatConversations: [],
            chatRecords: [],
            chatRecordsPagination: { pageSize: 10, pageNo: 1 },
            changeConversation: false,
            message: "",
            enableContext: false,
            displaySidecar: true,
            displayMenu: false,
            planDialogVisible: false,
            showMobilePayPage: false,
            displayNewChatDialog: false,
            selectedConversation: undefined,
            showConversationMenus: false,
            displayConversationDetail: false,
            detailConversation: {},
            tablePagination: {},
            composing: false,
            disabledInput: false,
            showWatingCreateOrder: false,
            selectedPlan: {},
            showPlan: true,
            showPayCodeImg: false,
            showPayResult: false,
            selectedMenuPlan: true,
            payTitle: '充值套餐',
            // payCodeImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEAAQMAAABmvDolAAAABlBMVEX///8AAABVwtN+AAABm0lEQVR42uyYPQ6jQAyF34iCkiNwFI42czSOwhEop0C81bMnhLDZdhUsXETC+VLEf88Yjz322M/aQFnFVBLrsAD74QoFLAA6e+rqwBmpnlyBgIl7Z3HYLAIC3BURABIrxjkzNDAxbb2q+fybSECrZ1LpnvOOr2V/d8AH0jDntPXrOOdUvw6xmwNvU4ZHln9rz52BYUFORxyATK7jfDcAmMqupBYXlJzIBfndmzEAk0oVaUHHdaRoTGrUUICVK5ToQ1DcFQuQevikdUEpCspn2YcA6G2rnZarEm1x+KiHEAAL9RXoAICRTGfFCQB4p/bK8/YaWBdZiQBoRrVlj6abAlQBsYCX4swZndo2o6dN5xoK8P2B61SoZU/1rP0hGqCqNnnRpOJiQblYBEBx4Elk7OFyJPl94GjR4ktCTtX+eRcLeF+0Whr9BnKeUSGA9tasdPda2lscEA1ouZWGiPY9/jyjAgED2c4eu7lOy14kwPqVfgO5NG8QoJ2vsn1OxTfbv5r37kC7aCnd9lqd/IYQDHjsscf+u/0JAAD//8Mbf10dS0nLAAAAAElFTkSuQmCC',
            payCodeImg: '',
            payCodeImgPrefix: 'data:image/png;base64,',
            payResult: '支付成功',
            titleSpace: ' ',
            diffPrice: '0',
            showUpgradeOrderTips: false,
        }
    },
    methods: {
        handleChatConversationsDragEnd(event) {

            let newIndex = event.newIndex
            let oldIndex = event.oldIndex
            
            if (newIndex > oldIndex) {

                let sortKey = this.chatConversations[newIndex].sortKey

                for (let i = oldIndex; i <= newIndex; i++) {
                    let tmpSortKey = this.chatConversations[i].sortKey
                    this.chatConversations[i].sortKey = sortKey
                    sortKey = tmpSortKey

                    this.updateChatConversationsSortKey(this.chatConversations[i].uid, this.chatConversations[i].sortKey)
                }
            } else if (newIndex < oldIndex) {

                let sortKey = this.chatConversations[newIndex].sortKey

                for (let i = oldIndex; i >= newIndex; i--) {
                    let tmpSortKey = this.chatConversations[i].sortKey

                    this.chatConversations[i].sortKey = sortKey
                    sortKey = tmpSortKey

                    this.updateChatConversationsSortKey(this.chatConversations[i].uid, this.chatConversations[i].sortKey)
                }

            }

        },
        initScroll() {
            let nav = document.getElementById('chat-area');
            new Drag(nav);
        },
        handleShowTopSidecar() {
            console.log('handleShowTopSidecar')
            this.showTopSidecar = true
            this.showMask = true
        },
        handleShowMobileSidecar() {
            console.log('handleShowMobileSidecar')
            this.showTopSidecar = true
            this.showMobileMask = true
        },
        handleCloseTopSidecar() {
            console.log('handleCloseTopSidecar')
            this.showTopSidecar = false
            this.showMask = false
        },
        handleKeydown(event) {
            if (this.composing) {
                return
            }

            if (event.key === 'Enter') {
                if (event.shiftKey) {
                    console.log('Shift + Enter')
                } else {
                    console.log('Enter')
                    this.createChatRecord();
                }
            }
        },
        handleCompositionStart() {
            console.log('handleCompositionStart')
            this.composing = true
        },
        handleCompositionEnd() {
            console.log('handleCompositionEnd')
            this.composing = false

        },
        init() {
            let chatArea = this.$refs.chatArea;
            console.log('init chatArea.scrollHeight', chatArea.scrollHeight)

            // const data = this.$route.query
            // this.userInfo.name = data.nickName;
            this.getUserInfo();

            this.getUserCoinsBalance();

            this.getChatConversations();

            // this.scrollToBottom();

            this.isMobile();

            this.getJsapiConfig();
        },
        showSidecar() {
            this.displaySidecar = !this.displaySidecar
            console.log(this.displaySidecar)
        },
        handleDisplayMenu() {
            this.displayMenu = !this.displayMenu
        },
        showPlansTab() {
            console.log('showPlansTab')
            this.planDialogVisible = true
            this.displayMenu = false
        },
        showPlansTabAndCloseSidecar() {
            console.log('showPlansTabAndCloseSidecar')
            this.showMobilePayPage = true
            this.showTopSidecar = false
        },
        showSettingsTab() {
            console.log('showSettingsTab')
        },
        scrollToTop() {
            let div = this.$refs.chatArea;
            div.scrollTop = 0;
        },
        scrollToBottom() {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.mobileLogin && this.isWeixin()) {
                        let div = document.getElementById('chat-area-mobile')
                        // alert(div.scrollTop)
                        // alert(div.scrollHeight)
                        // alert(div)
                        div.scrollTop = div.scrollHeight
                    } else if (this.mobileLogin && !this.isWeixin()) {
                        let chatAreaMobile = this.$refs.chatAreaMobile;
                        chatAreaMobile.scrollTop = chatAreaMobile.scrollHeight;
                    } else {
                        let chatArea = this.$refs.chatArea;
                        chatArea.scrollTop = chatArea.scrollHeight;
                    }
                }, 100)

            })
        },

        async getJsapiConfig() {
            let res = await _getJsapiConfig()
            console.log(res)

            if (res.success) {
                console.log('getJsapiConfig', res)
                let data = res.result[0]
                wx.config({
                    debug: false,
                    appId: data.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.signature, // 必填，签名，见附录1
                    // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
                    jsApiList: data.jsApiList
                })

                wx.ready(function() {
                })
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },
        async logout() {
            let res = await _logout();
            console.log(res)

            if (res.success) {
                this.$router.push({
                    path: '/login'
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async getUserInfo() {
            let res = await _getUserInfo();
            console.log(res)

            if (res.success) {
                this.userInfo.name = res.result[0].nickName
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        handleMobileSelectConversation(uid) {
            this.handleSelectConversation(uid);

            this.showTopSidecar = false;
        },

        handleSelectConversation(uid) {
            console.log('selected conversation id', uid)

            if (this.selectedConversation && this.selectedConversation.uid == uid) {
                return
            }

            this.changeConversation = true;

            const selectedConversation = this.chatConversations.find(x => x.uid == uid)

            this.selectedConversation = selectedConversation

            this.chatRecords = [];
            this.chatRecordsPagination = { pageSize: 10, pageNo: 1, total: 0};

            console.log('aaaaaaaaaa isScrollTop', this.isScrollTop)
            console.log('aaaaaaaaaa handleSelectConversation', this.chatRecords, this.selectedConversation.uid)

            this.getChatRecords()

            // this.scrollToBottom();

            console.log('selected conversation', this.selectedConversation)
        },

        handleDropdownMenuItemClick(command) {
            console.log(command)
            const commands = command.split(":")
            console.log(commands)

            switch (commands[0]) {
                case 'handleDetailConversation':
                    const conversation = this.chatConversations.find(x => x.uid == commands[1])
                    this.detailConversation = {
                        title: conversation.title,
                        model: conversation.gptModel,
                        prompt: conversation.prompt,
                    }
                    this.displayConversationDetail = true;
                    break
                case 'handleDeleteConversation':
                    this.handleDeleteConversation(commands[1])
                    break
            }
        },

        handleDeleteConversation(uid) {
            console.log('delete conversation id', uid)

            this.deleteConversation(uid)
        },

        handleSelectedPlan(data) {
            console.log('selected plan', data);
            this.selectedPlan = data;
        },

        handleClosePayCodeImg(data) {
            this.showPayCodeImg = false
        },

        handleClosePayResult(data) {
            this.showPayCodeImg = false
            this.showPayResult = false
            this.showPlan = true
        },

        handleCloseUpgradeOrderTips(data) {
            this.showUpgradeOrderTips = false
            this.showPlan = true
        },

        handleConfirmUpgradeOrder(data) {
            console.log('handleConfirmUpgradeOrder')
            this.showWatingCreateOrder = true
            this.upgradeOrder()
        },

        handleSelectedMenu(data) {
            console.log('handleSelectedMenu', data)
            if (data == 'plan') {
                this.selectedMenuPlan = true
            } else if (data == 'order') {
                this.selectedMenuPlan = false
            }
        },

        handlePayOrder() {
            console.log('handlePayOrder', this.selectedMenuPlan)

            if (!this.selectedMenuPlan) {
                this.planDialogVisible = false
                return
            }

            if (this.userInfo.monthlyInfo.planType == 'Monthly-Subscription' && this.selectedPlan.planType !== 'Pay-As-You-Go' && !this.userInfo.monthlyInfo.isExpired) {
                if (this.userInfo.monthlyInfo.planLevel < this.selectedPlan.planLevel) {
                    // 套餐升级, 先获取差价，然后再升级
                    this.showWatingCreateOrder = true
                    this.preUpgradeOrder()

                    return

                }

            }

            console.log('handlePayOrder');
            this.showWatingCreateOrder = true

            this.createPayOrder();
        },

        async preUpgradeOrder() {
            let param = {
                planUid: this.userInfo.monthlyInfo.planUid,
                upgradePlanUid: this.selectedPlan.uid,
            }


            let res = await _preUpgradeOrder(param);

            console.log(res)
            if (res.success) {
                this.showWatingCreateOrder = false
                this.diffPrice = res.result[0].diffPrice
                this.showUpgradeOrderTips = true


            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async upgradeOrder() {
            let param = {
                payType: 'WXPAY',
                planUid: this.userInfo.monthlyInfo.planUid,
                upgradePlanUid: this.selectedPlan.uid,
                mobileAgent: this.mobileLogin,
                h5InfoType: 'Android',
                isWeixin: this.isWeixin(),  
            }

            if (this.isIOS()) {
                param.h5InfoType = 'IOS'
            }

            console.log(param)

            let res = await _upgradeOrder(param);
            console.log(res)

            this.showWatingCreateOrder = false

            if (res.success) {
                if (this.isWeixin()) {
                    let body = res.result[0].prepayBody
                    WeixinJSBridge.invoke('getBrandWCPayRequest', {
                        "appId": body.appId,     //公众号ID，由商户传入     
                        "timeStamp": body.timeStamp,     //时间戳，自1970年以来的秒数     
                        "nonceStr": body.nonceStr,      //随机串     
                        "package": body.package,
                        "signType": body.signType,     //微信签名方式：     
                        "paySign": body.paySign,
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            that.$notify({
                                type: 'success',
                                message: '支付成功',
                                duration: 3000,
                            });
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        }
                    });
                } else if (this.mobileLogin) {
                    window.location.href = res.result[0].h5Url
                } else {
                    this.showPayCodeImg = true
                    this.payCodeImg = this.payCodeImgPrefix + res.result[0].codeUrl

                    this.getOrderStatus(res.result[0].orderId);
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async createPayOrder() {
            let param = {
                payType: 'WXPAY',
                planUid: this.selectedPlan.uid,
                mobileAgent: this.mobileLogin,
                h5InfoType: 'Android',
                isWeixin: this.isWeixin(),
            }

            if (this.isIOS()) {
                param.h5InfoType = 'IOS'
            }

            console.log(param)

            let res = await _pay(param);
            console.log(res)

            this.showWatingCreateOrder = false

            if (res.success) {
                if (this.isWeixin()) {
                    let body = res.result[0].prepayBody
                    WeixinJSBridge.invoke('getBrandWCPayRequest', {
                        "appId": body.appId,     //公众号ID，由商户传入     
                        "timeStamp": body.timeStamp,     //时间戳，自1970年以来的秒数     
                        "nonceStr": body.nonceStr,      //随机串     
                        "package": body.package,
                        "signType": body.signType,     //微信签名方式：     
                        "paySign": body.paySign,
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            that.$notify({
                                type: 'success',
                                message: '支付成功',
                                duration: 3000,
                            });
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        }
                    });
                } else if (this.mobileLogin) {
                    window.location.href = res.result[0].h5Url
                } else {
                    this.showPayCodeImg = true
                    this.payCodeImg = this.payCodeImgPrefix + res.result[0].codeUrl

                    this.getOrderStatus(res.result[0].orderId);
                }

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        getOrderStatus(orderId) {
            this.getOrderTimer = setInterval(() => {
                this.getOrder(orderId)
            }, 5000);
        },

        async updateChatConversationsSortKey(uid, newSortKey) {
            let param = {
                uid: uid,
                newSortKey: newSortKey
            }

            let res = await _updateChatConversationsSortKey(param)
            console.log(res)

            if (res.success) {
                // that.$notify({
                //     type: 'success',
                //     message: '复制成功',
                //     duration: 3000,
                // });
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async updateChatConversationsInfo(uid, title, systemPrompt) {
            let param = {
                uid: uid,
                title: title,
                systemPrompt: systemPrompt
            }

            let res = await _updateChatConversationsInfo(param)
            console.log(res)

            if (res.success) {

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async getOrder(orderId) {
            let param = {
                id: orderId
            }

            let res = await _getOrderStatus(param)
            console.log(res)
            if (res.success) {
                switch (res.result[0].status) {
                    case 'SUCCESS':
                        this.payResult = `支付成功! 
                        如遇网络延迟, 请刷新页面查看余额`
                        this.showPlan = false
                        this.showPayCodeImg = false
                        this.showPayResult = true

                        clearInterval(this.getOrderTimer);
                        this.getUserCoinsBalance();
                        break
                    case 'CLOSED':
                        this.payResult = '支付取消'
                        this.showPlan = false
                        this.showPayCodeImg = false
                        this.showPayResult = true
                        clearInterval(this.getOrderTimer);
                        break
                    case 'PAYERROR':
                        this.payResult = '支付失败'
                        this.showPlan = false
                        this.showPayCodeImg = false
                        this.showPayResult = true
                        clearInterval(this.getOrderTimer);
                        break
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async getChatConversations() {
            let res = await _getChatConversations()
            console.log(res)
            console.log('this.selectedConversation == undefined', this.selectedConversation == undefined)

            if (res.success) {
                this.chatConversations = res.result[0].chatConversations

                if (this.chatConversations.length <= 0) {
                    return;
                }

                if (this.selectedConversation == undefined) {
                    this.selectedConversation = this.chatConversations[0]
                    this.getChatRecords()

                    // this.scrollToBottom();
                } else {
                    let exist = this.chatConversations.some(x => x.uid == this.selectedConversation.uid)
                    if (!exist) {
                        this.selectedConversation = this.chatConversations[0]
                    }
                    this.getChatRecords()
                    // this.scrollToBottom();
                }

                console.log('this.selectedConversation', this.selectedConversation)

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async createConversation(params) {
            this.displayNewChatDialog = false
            console.log(params)

            let res = await _createChatConversation(params);
            console.log(res)

            if (res.success) {
                this.selectedConversation = res.result[0]
                console.log('create new conversation', this.selectedConversation)
                this.chatRecords = [];
                this.chatRecordsPagination = { pageSize: 10, pageNo: 1, total: 0};

                this.getChatConversations()
            }
            else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async deleteConversation(uid) {
            let params = {
                uid: uid
            }

            let res = await _deleteChatConversation(params)
            console.log(res)

            if (res.success) {
                this.$notify({
                    title: '成功',
                    message: res.message,
                    type: 'success',
                    duration: 3000,
                });

                this.chatRecords = [];
                this.chatRecordsPagination = { pageSize: 10, pageNo: 1, total: 0};
                
                this.getChatConversations();
                this.clearRecords();
            }
        },

        async getChatRecords() {
            const { pageSize, pageNo } = this.chatRecordsPagination;
            let params = {
                conversationUid: this.selectedConversation.uid,
                pageSize: pageSize,
                pageNo: pageNo,
            }
            console.log('aaaaaaaaaa', params)
            console.log('aaaaaaaaaa getChatRecords isScrollTop', this.isScrollTop)
            if (this.isScrollTop) {
                params.pageNo = params.pageNo + 1
            }


            let res = await _getChatRecords(params)
            console.log('aaaaaaaaaa',res)

            if (res.success) {
                this.chatRecordsPagination = res.pagination;
                let records = res.result[0].chatgptRecords
                if (this.chatRecords.length > 0) {
                    records.forEach(x => {
                        this.chatRecords.unshift(x);
                    })
                    console.log('aaaaaaaaaa append', this.chatRecords,this.isScrollTop);
                } else {
                    records = records.reverse()
                    this.chatRecords = records
                    console.log('aaaaaaaaaa init', this.chatRecords,this.isScrollTop);
                }

                console.log('aaaaaaaaaa after', this.chatRecords, this.selectedConversation.uid)

                this.$nextTick(() => {
                    this.$refs.chatgptContainer.forEach(x => {
                        x.addCopyButtons()
                    })

                    this.$refs.chatuserContainer.forEach(x => {
                        x.addCopyButtons()
                    })

                    console.log('aaaaaaaaaa nextTick isScrollTop', this.isScrollTop)
                    if (!this.isScrollTop) {
                        this.scrollToBottom();
                    }

                    this.changeConversation = false
                    this.isScrollTop = false
                    console.log('aaaaaaaaaa nextTick done isScrollTop', this.isScrollTop)
                })
                // console.log('records',records)

                // this.chatRecords.unshift(...records)
                // console.log('this.chatRecords', this.chatRecords)
            }
            else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async createChatRecord() {
            console.log('createChatRecord', this.message)
            if (this.message == '') {
                this.$notify({
                    title: '警告',
                    message: '输入不能为空' + this.mobileLogin,
                    type: 'warning',
                    duration: 3000,
                })

                this.message = '';
                return
            }
            let message = this.message;
            let params = {
                conversationUid: this.selectedConversation.uid,
                prompt: message,
                enableContext: this.enableContext,
            }

            this.chatRecords.push({
                role: 'user',
                message: message,
            })

            this.scrollToBottom();

            this.message = '';

            this.disabledInput = true;

            try {
                let response = await fetch('/earthtail/api/v1/earthtails/chatgpts', {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                console.log(response)

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                this.chatRecords.push({
                    role: 'assistant',
                    message: '',
                })

                this.scrollToBottom();

                const reader = response.body.getReader();
                const textDecoder = new TextDecoder();
                let result = true;
                let output = ''

                while (result) {
                    const { done, value } = await reader.read();

                    if (done) {
                        console.log('Stream ended');
                        result = false;
                        break;
                    }

                    const chunkText = textDecoder.decode(value);

                    try {
                        let result = JSON.parse(chunkText)
                        if (result.code == 2000200) {
                            MessageBox.confirm(result.message, '提示', {
                                confirmButtonText: '确定',
                                type: 'warning',
                                center: true,
                                showCancelButton: false
                            }).then(() => {
                                // window.location.href = '/login'
                                // TODO: 跳转到充值页面
                                this.getChatRecords();
                            })

                            result = false;
                            break;
                        }

                    } catch (err) {
                        // console.log(err)
                    }

                    let lastRecord = this.chatRecords.at(-1)
                    if (chunkText.includes("生成图片成功")) {
                        let chunkTextObject = JSON.parse(chunkText)
                        console.log(chunkTextObject.result[0])

                        lastRecord.message = `![image](${chunkTextObject.result[0]})`
                    } else {
                        lastRecord.message += chunkText;
                    }


                    let chatRecords = this.chatRecords.slice(0, -1)
                    chatRecords.push(lastRecord)
                    this.chatRecords = chatRecords

                    output += chunkText;
                    console.log('Received chunk:', chunkText);

                    this.scrollToBottom();

                    this.$nextTick(() => {
                        this.$refs.chatgptContainer.forEach(x => {
                            x.addCopyButtons()
                        })

                        this.$refs.chatuserContainer.forEach(x => {
                            x.addCopyButtons()
                        })
                    })
                }

            } catch (e) {
                console.log(e)
                this.$notify.error({
                    title: '错误',
                    message: e,
                    duration: 3000,
                });
            }

            this.disabledInput = false;
            this.getUserCoinsBalance();
        },

        async getUserCoinsBalance() {
            let res = await _getUserCoinsBalance()
            console.log(res)

            if (res.success) {
                let coins = res.result[0].coins
                this.userInfo.coins = coins
                this.userInfo.monthlyInfo = res.result[0].monthlyInfo
            }
            else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        clearRecords() {
            if (this.selectedConversation == undefined) {
                this.chatRecords = []
            }
        },

        setUserImg() {
            switch (this.userInfo.gender) {
                case 0:
                    this.userInfo.img = require('../assets/images/user_girl.png');
                    break;
                case 1:
                    this.userInfo.img = require('../assets/images/user_boy.png');
                    break;
                case 2:
                    this.userInfo.img = require('../assets/images/user_default.png');
                    break;
            }
        },
        isMobile() {   //判断是否为移动端
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            console.log('userAgent', userAgent, /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent))
            this.mobileLogin = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
            // Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36
            this.userAgent = userAgent;
        },
        isIOS() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /iP(hone|od)/.test(userAgent);
        },
        isWeixin() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /MicroMessenger/.test(userAgent);
        },
        handleScroll(event) {
            const container = event.target;
            const scrollTop = container.scrollTop;

            if (this.changeConversation) {
                return
            }

            if (scrollTop === 0 && !this.isLoading) {
                this.isLoading = true;
                this.isScrollTop = true;

                console.log('aaaaaaaaaa scroll get records');
                this.getChatRecords();

                this.isLoading = false;
                
            }
        }
    },
    created() {
        this.setUserImg();
    },
    beforeDestroy() {
        clearInterval(this.getOrderTimer);
        // window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style lang="less" scoped>
@fendi-yellow: rgba(244, 188, 78);
@fendi-yellow-opacity: rgba(237, 217, 177, 0.936);
@fendi-black: rgba(21, 22, 20);

.chat-page-mobile {
    // background-color: #269038ca;
    width: 100vw;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .customer-container {
        position: absolute;
        top: 3px;
        right: 20px;
        cursor: pointer;
        z-index: 1000000;
    }

    .wechat-qrcode {
        width: 20px;
        height: 20px;
    }

    .customer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .customer-img {
        width: 25px;
        height: 25px;
    }

    .customer-text {
        font-size: 8px;
        color: #f78049;
        text-align: center;
    }

    .chat-mobile-area-container {
        // background-color: #ee2828ca;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .chat-area-title {
            border-bottom: 1px solid #D3D0D8;
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            // position: relative;
            

            .chat-area-title-sidecar {
                margin-left: 5px;
                top: 5px;
                line-height: 40px;
                position: absolute;

                .chat-area-title-sidecar-icon {
                    width: 25px;
                    height: 20px;

                }
            }

            .chat-area-title-text {
                top: 50%;
                font-size: 20px;
                line-height: 40px;
                position: relative;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .chat_area {
            overflow: hidden;
            overflow-y: auto;
            // background-color: #f78049;
            margin-top: 10px;
            width: 100%;
            height: calc(100% - 95px);
            display: flex;
            flex-direction: column;
            align-items: center;

            .etai-content {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .user-content {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .chat_input {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 99%;
            height: 40px;
            // height: 62px;
            // left: 50%;
            // bottom: 25px;
            align-self: center;
            // position: absolute;
            // transform: translate(-50%, 0);
            border: 1px solid #D3D0D8;
            border-bottom: 1px solid #D3D0D8;

            ::v-deep .chat-el-input .el-textarea__inner {
                border: 0;
                // overflow-y: hidden;
                font-size: 18px;
            }

            .chat-el-button {
                width: 30px;
                height: 32px;
                font-size: 25px;
                color: rgba(69, 163, 230, 0.608);
                text-align: center;
                margin-right: 5px;
                // background-color: #817575;
            }

            .chat_context_switch {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: relative;
                // left: -50px;
                // right: -50px;
                line-height: 40px;
                font-size: 20px;

                .chat_context_switch_text {
                    width: 100px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    // background-color: #f78049;
                    font-size: 10px;
                }
            }
        }
    }

    .chat-top-sidecar {
        z-index: 100000;
        box-shadow: -16px 0px 20px 4px rgba(0, 0, 0, 0.3);
        background-color: rgba(65, 65, 65, 1);
        border-radius: 22px;
        position: absolute;
        top: 42px;
        bottom: 69px;
        width: 310px;
        height: 90%;
        transform: translateX(-100%) rotateY(-90deg);
        transform-style: preserve-3d;
        perspective: 1000px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .close-sidecar {
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: absolute;
            right: -40px;
            text-align: center;
            font-size: 20px;
            color: #020202;
            border-style: solid;
            border-width: 2.5px;
        }

        .chat_sidecar_title {
            width: 80%;
            height: 39px;
            margin: 28px 0 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 50px;

            .image-wrapper {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                height: 39px;
                width: 39px;

                .logo {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                }
            }

            .title_text {
                width: 94px;
                height: 20px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 20px;
                font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 20px;
                margin: 0 0 0 15px;
            }

            .pencil_bg_img {
                height: 21px;
                background: url(../assets/images/pencil_bg.png) 100% no-repeat;
                background-size: 100% 100%;
                width: 21px;
                margin: 0 0 0 100px;
                cursor: pointer;

                .pencil_img {
                    width: 14px;
                    height: 14px;
                    margin: 1px 0 0 6px;
                }
            }
        }

        .chat_sidecar_scroll_container {
            position: relative;
            height: calc(100% - 250px);
            width: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .history_list_item_draggable {
                width: 90%;
            }

            .history_list_item_draggable .history_list_item {
                position: relative;
                flex-shrink: 0;
                width: 100%;
                height: 45px;
                background-color: rgba(77, 77, 77, 1);
                border-radius: 12px;
                margin: 14px 0 0 0;
                display: flex;
                cursor: pointer;
                flex-direction: row;
                margin-bottom: 10px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

                .history_list_item_content {
                    width: 100%;
                    height: 17px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 13px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 17px;
                    margin: 14px 0 0 16px;
                }

                .dots-container {
                    width: 20px;
                    height: 5px;
                    margin: 10px 14px 0px 100px;


                    .el-dropdown {
                        width: 20px;
                        height: 20px;
                        // margin: 20px 14px 0px 100px;

                    }

                    .history_list_item_img {
                        width: 20px;
                        height: 5px;
                        // margin: 20px 14px 0px 100px;
                    }
                }


            }
        }

        .chat_sidecar_plans {
            width: 290px;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: @fendi-yellow;
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            border-radius: 12px;
            margin: 25px 0 0 0;
            bottom: 150px;
            position: absolute;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .pay-content {
                color: @fendi-black;
            }
        }

        .chat_sidecar_settings {
            width: 290px;
            // z-index: -100;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: rgba(77, 77, 77, 1);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            background-size: 266px 45px;
            border-radius: 12px;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            margin: 25px 0 0 0;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .chat-sidecar-user-card {
            width: 290px;
            height: 120px;
            background: linear-gradient(@fendi-yellow-opacity, @fendi-yellow);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            // background-size: 266px 45px;
            border-radius: 17px;
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .membership-container {
                position: absolute;
                display: flex;
                flex-direction: row;
                background-color: rgba(107, 62, 62, 0.3);
                align-items: center;
                width: 100px;
                height: 30px;
                border-bottom-left-radius: 12px;
                right: 0px;
                top: 0px;
                font-size: 14px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
                font-family: MesloLGS_NF_Bold;

                img {
                    margin-top: -5px;
                    width: 30px;
                    height: 30px;
                }
            }

            .pay-btn {
                position: absolute;
                background-color: rgba(107, 62, 62, 0.3);
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 15px;
                color: rgba(255, 255, 255, 0.8);
                cursor: pointer;

                left: 214px;
                top: 80px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            }


            .chat_sidecar_user {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .chat_sidecar_user_icon {
                    background-color: rgba(255, 255, 255, 0.613);
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin: 0 0 0 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .user_icon {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                    }

                    .user_name_icon_text {
                        width: 25px;
                        height: 22px;
                        overflow-wrap: break-word;
                        color: rgba(0, 0, 0, 0.7);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 22px;
                    }
                }

                .chat-sidecar-user-card-content {
                    display: flex;
                    flex-direction: column;

                    .user_name_text {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 16px;
                        font-family: MesloLGS_NF_Bold;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .user-card-membership-expiration {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .time-expired {
                        margin-left: 14px;
                        text-align: left;
                        overflow-wrap: break-word;
                        color: rgb(164, 155, 155);
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                    }
                }

                .user_name_text {
                    width: 156px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin: 0 0 0 14px;
                }

                .user-monthly {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 20px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 80px;
                }
            }

            .user-monthly-card {
                display: flex;
                flex-direction: row;

                .user-coins-prefix {
                    width: 60px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 12px;
                    font-family: MesloLGS_NF_Regular;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-left: 14px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 18px;
                    font-family: MesloLGS_NF_Bold;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                }

                .title {
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    margin: 0 0 0 10px;
                }

                .time {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(241, 149, 51, 0.893);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }

                .time-expired {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(164, 155, 155, 0.887);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }
            }
        }
    }

    /deep/ .el-dialog {
        margin-top: 20px;
        width: 90%;
    }

    .pay-mobile-dialog {
        width: 90%;
    }

}

.chat_page {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    // align-items: center;

    .customer-container {
        position: absolute;
        top: 20%;
        right: 20px;
        cursor: pointer;
    }

    .wechat-qrcode {
        width: 40px;
        height: 40px;
    }

    .customer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .customer-img {
        width: 40px;
        height: 40px;
    }

    .customer-text {
        font-size: 12px;
        color: #f78049;
        text-align: center;
    }

    .mask {
        width: 100vw;
        height: 100%;
        background-color: #312e2e74;
        z-index: 99;
    }

    .chat_sidecar_arrow_container {
        width: 40px;
        height: 40px;
        position: relative;
        left: 20px;
        top: 50%;
        cursor: pointer;

        .chat_sidecar_arrow {
            transform: translate(20%, -70%) rotateY(0deg);
            ;
            transform-style: preserve-3d;
            opacity: 0;
            // background-color: rgba(136, 136, 136, 1);
            height: 100%;
        }
    }

    .chat_sidecar {
        box-shadow: -16px 0px 20px 4px rgba(0, 0, 0, 0.3);
        background-color: rgba(65, 65, 65, 1);
        border-radius: 22px;
        position: absolute;
        left: 58px;
        top: 42px;
        bottom: 69px;
        width: 310px;
        transform: translateX(-100%) rotateY(-90deg);
        transform-style: preserve-3d;
        perspective: 1000px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .close-sidecar {
            display: none;
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: absolute;
            right: -40px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            border-style: solid;
            border-width: 2.5px;
        }

        .chat_sidecar_title {
            width: 80%;
            height: 39px;
            margin: 28px 0 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 50px;

            .image-wrapper {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                height: 39px;
                width: 39px;

                .logo {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                }
            }

            .title_text {
                width: 94px;
                height: 20px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 20px;
                font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 20px;
                margin: 0 0 0 15px;
            }

            .pencil_bg_img {
                height: 21px;
                background: url(../assets/images/pencil_bg.png) 100% no-repeat;
                background-size: 100% 100%;
                width: 21px;
                margin: 0 0 0 100px;
                cursor: pointer;

                .pencil_img {
                    width: 14px;
                    height: 14px;
                    margin: 1px 0 0 6px;
                }
            }
        }

        .chat_sidecar_scroll_container {
            position: relative;
            height: 66%;
            width: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .history_list_item_draggable {
                width: 90%;
            }

            .history_list_item_draggable .history_list_item {
                position: relative;
                flex-shrink: 0;
                cursor: pointer;
                width: 100%;
                height: 45px;
                background-color: rgba(77, 77, 77, 1);
                border-radius: 12px;
                margin: 14px 0 0 0;
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

                .history_list_item_content {
                    width: 100%;
                    height: 17px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 13px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 17px;
                    margin: 14px 0 0 16px;
                }

                .dots-container {
                    width: 20px;
                    height: 5px;
                    margin: 10px 14px 0px 100px;


                    .el-dropdown {
                        width: 20px;
                        height: 20px;
                        // margin: 20px 14px 0px 100px;

                    }

                    .history_list_item_img {
                        width: 20px;
                        height: 5px;
                        // margin: 20px 14px 0px 100px;
                    }
                }


            }
        }

        .chat_sidecar_plans {
            width: 290px;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: @fendi-yellow;
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            background-size: 266px 45px;
            border-radius: 12px;
            margin: 25px 0 0 0;
            bottom: 150px;
            position: absolute;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .pay-content {
                color: @fendi-black;
            }
        }

        .chat_sidecar_settings {
            width: 290px;
            // z-index: -100;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: rgba(77, 77, 77, 1);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            background-size: 266px 45px;
            border-radius: 12px;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            margin: 25px 0 0 0;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .chat-sidecar-user-card {
            width: 290px;
            height: 120px;
            background: linear-gradient(@fendi-yellow-opacity, @fendi-yellow);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            // background-size: 266px 45px;
            border-radius: 17px;
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .membership-container {
                position: absolute;
                display: flex;
                flex-direction: row;
                background-color: rgba(107, 62, 62, 0.3);
                align-items: center;
                width: 100px;
                height: 30px;
                border-bottom-left-radius: 12px;
                right: 0px;
                top: 0px;
                font-size: 14px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
                font-family: MesloLGS_NF_Bold;

                img {
                    margin-top: -5px;
                    width: 30px;
                    height: 30px;
                }
            }

            .pay-btn {
                position: absolute;
                background-color: rgba(107, 62, 62, 0.3);
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 15px;
                color: rgba(255, 255, 255, 0.8);
                cursor: pointer;
                left: 214px;
                top: 80px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            }


            .chat_sidecar_user {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .chat_sidecar_user_icon {
                    background-color: rgba(255, 255, 255, 0.613);
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin: 0 0 0 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .user_icon {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                    }

                    .user_name_icon_text {
                        width: 25px;
                        height: 22px;
                        overflow-wrap: break-word;
                        color: rgba(0, 0, 0, 0.7);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 22px;
                    }
                }

                .chat-sidecar-user-card-content {
                    display: flex;
                    flex-direction: column;

                    .user_name_text {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 16px;
                        font-family: MesloLGS_NF_Bold;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .user-card-membership-expiration {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .time-expired {
                        margin-left: 14px;
                        text-align: left;
                        overflow-wrap: break-word;
                        color: rgb(164, 155, 155);
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                    }
                }

                .user_name_text {
                    width: 156px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin: 0 0 0 14px;
                }

                .user-monthly {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 20px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 80px;
                }
            }

            .user-monthly-card {
                display: flex;
                flex-direction: row;

                .user-coins-prefix {
                    width: 60px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 12px;
                    font-family: MesloLGS_NF_Regular;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-left: 14px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 18px;
                    font-family: MesloLGS_NF_Bold;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                }

                .title {
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    margin: 0 0 0 10px;
                }

                .time {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(241, 149, 51, 0.893);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }

                .time-expired {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(164, 155, 155, 0.887);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }
            }
        }
    }

    .chat-top-sidecar {
        z-index: 100;
        box-shadow: -16px 0px 20px 4px rgba(0, 0, 0, 0.3);
        background-color: rgba(65, 65, 65, 1);
        border-radius: 22px;
        position: absolute;
        top: 42px;
        bottom: 69px;
        width: 310px;
        transform: translateX(-100%) rotateY(-90deg);
        transform-style: preserve-3d;
        perspective: 1000px;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .close-sidecar {
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: absolute;
            right: -40px;
            text-align: center;
            font-size: 20px;
            color: #fff;
            border-style: solid;
            border-width: 2.5px;
        }

        .chat_sidecar_title {
            width: 80%;
            height: 39px;
            margin: 28px 0 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 50px;

            .image-wrapper {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                height: 39px;
                width: 39px;

                .logo {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                }
            }

            .title_text {
                width: 94px;
                height: 20px;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 20px;
                font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                font-weight: normal;
                text-align: left;
                white-space: nowrap;
                line-height: 20px;
                margin: 0 0 0 15px;
            }

            .pencil_bg_img {
                height: 21px;
                background: url(../assets/images/pencil_bg.png) 100% no-repeat;
                background-size: 100% 100%;
                width: 21px;
                margin: 0 0 0 100px;
                cursor: pointer;

                .pencil_img {
                    width: 14px;
                    height: 14px;
                    margin: 1px 0 0 6px;
                }
            }
        }

        .chat_sidecar_scroll_container {
            position: relative;
            height: 66%;
            width: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .history_list_item_draggable {
                width: 90%;
            }

            .history_list_item_draggable .history_list_item {
                position: relative;
                flex-shrink: 0;
                width: 100%;
                height: 45px;
                background-color: rgba(77, 77, 77, 1);
                border-radius: 12px;
                margin: 14px 0 0 0;
                display: flex;
                cursor: pointer;
                flex-direction: row;
                margin-bottom: 10px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

                .history_list_item_content {
                    width: 100%;
                    height: 17px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 13px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 17px;
                    margin: 14px 0 0 16px;
                }

                .dots-container {
                    width: 20px;
                    height: 5px;
                    margin: 10px 14px 0px 100px;


                    .el-dropdown {
                        width: 20px;
                        height: 20px;
                        // margin: 20px 14px 0px 100px;

                    }

                    .history_list_item_img {
                        width: 20px;
                        height: 5px;
                        // margin: 20px 14px 0px 100px;
                    }
                }


            }
        }

        .chat_sidecar_plans {
            width: 290px;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: @fendi-yellow;
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            border-radius: 12px;
            margin: 25px 0 0 0;
            bottom: 150px;
            position: absolute;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .pay-content {
                color: @fendi-black;
            }
        }

        .chat_sidecar_settings {
            width: 290px;
            // z-index: -100;
            height: 45px;
            color: rgba(0, 0, 0, 0.7);
            background-color: rgba(77, 77, 77, 1);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            background-size: 266px 45px;
            border-radius: 12px;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            margin: 25px 0 0 0;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .chat-sidecar-user-card {
            width: 290px;
            height: 120px;
            background: linear-gradient(@fendi-yellow-opacity, @fendi-yellow);
            flex-shrink: 0;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            // background-size: 266px 45px;
            border-radius: 17px;
            position: absolute;
            bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .membership-container {
                position: absolute;
                display: flex;
                flex-direction: row;
                background-color: rgba(107, 62, 62, 0.3);
                align-items: center;
                width: 100px;
                height: 30px;
                border-bottom-left-radius: 12px;
                right: 0px;
                top: 0px;
                font-size: 14px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
                font-family: MesloLGS_NF_Bold;

                img {
                    margin-top: -5px;
                    width: 30px;
                    height: 30px;
                }
            }

            .pay-btn {
                position: absolute;
                background-color: rgba(107, 62, 62, 0.3);
                width: 60px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 15px;
                color: rgba(255, 255, 255, 0.8);
                cursor: pointer;
                left: 214px;
                top: 80px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            }


            .chat_sidecar_user {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .chat_sidecar_user_icon {
                    background-color: rgba(255, 255, 255, 0.613);
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    margin: 0 0 0 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .user_icon {
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                    }

                    .user_name_icon_text {
                        width: 25px;
                        height: 22px;
                        overflow-wrap: break-word;
                        color: rgba(0, 0, 0, 0.7);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: normal;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 22px;
                    }
                }

                .chat-sidecar-user-card-content {
                    display: flex;
                    flex-direction: column;

                    .user_name_text {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 16px;
                        font-family: MesloLGS_NF_Bold;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .user-card-membership-expiration {
                        width: 156px;
                        height: 20px;
                        overflow-wrap: break-word;
                        color: @fendi-black;
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                        text-align: left;
                        white-space: nowrap;
                        line-height: 20px;
                        margin: 0 0 0 14px;
                    }

                    .time-expired {
                        margin-left: 14px;
                        text-align: left;
                        overflow-wrap: break-word;
                        color: rgb(164, 155, 155);
                        font-size: 12px;
                        font-family: MesloLGS_NF_Regular;
                        font-weight: normal;
                    }
                }

                .user_name_text {
                    width: 156px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin: 0 0 0 14px;
                }

                .user-monthly {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 20px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-right: 80px;
                }
            }

            .user-monthly-card {
                display: flex;
                flex-direction: row;

                .user-coins-prefix {
                    width: 60px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 12px;
                    font-family: MesloLGS_NF_Regular;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                    margin-left: 14px;
                }

                .user-coins {
                    width: 20px;
                    height: 20px;
                    overflow-wrap: break-word;
                    color: @fendi-black;
                    font-size: 18px;
                    font-family: MesloLGS_NF_Bold;
                    font-weight: normal;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 20px;
                }

                .title {
                    overflow-wrap: break-word;
                    color: rgba(241, 146, 68, 0.893);
                    font-size: 14px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                    margin: 0 0 0 10px;
                }

                .time {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(241, 149, 51, 0.893);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }

                .time-expired {
                    margin-left: 20px;
                    text-align: right;
                    align-self: flex-end;
                    overflow-wrap: break-word;
                    color: rgba(164, 155, 155, 0.887);
                    font-size: 11px;
                    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                    font-weight: normal;
                }
            }
        }
    }

    .chat_area_container {
        position: absolute;
        background-color: rgba(140, 29, 29, 0);
        width: calc(100% - 500px);
        height: calc(100% - 120px);
        display: flex;

        .chat-area-title-sidecar {
            display: none;
            top: 20px;
            position: absolute;

            .chat-area-title-sidecar-icon {
                width: 25px;
                height: 20px;
            }
        }

        .chat-area-title {
            top: 20px;
            font-size: 20px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .chat_area {
            overflow: hidden;
            overflow-y: auto;
            top: 50px;
            bottom: 69px;
            position: relative;
            background-color: rgba(255, 255, 255, 0);
            width: 100%;
            height: 90%;
            border-radius: 24px;

            .etai-content {
                width: 60%;
                margin-top: 20px;
                margin-left: 20px;
                margin-bottom: 20px;
            }

            .user-content {
                width: 60%;
                float: right;
                margin-top: 20px;
                margin-bottom: 20px;
                margin-right: 20px;
            }
        }

        

        .chat_input {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 792px;
            // height: 62px;
            border-radius: 5px;
            left: 50%;
            bottom: -50px;
            position: absolute;
            transform: translate(-50%, 0);
            border: 1px solid #D3D0D8;

            ::v-deep .chat-el-input .el-textarea__inner {
                border: 0;
                // overflow-y: hidden;
                font-size: 18px;
            }

            .chat_context_switch {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                // position: relative;
                // left: -50px;
                left: 50px;
                line-height: 40px;
                font-size: 20px;

                .chat_context_switch_text {
                    width: 100px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    // background-color: #f78049;
                    font-size: 10px;
                }
            }

            .chat-el-button {
                width: 30px;
                height: 32px;
                font-size: 25px;
                color: rgba(69, 163, 230, 0.608);
                text-align: center;
                margin-right: 5px;
                // background-color: #817575;
            }
        }
    }

    /deep/ .el-dialog {
        border-radius: 24px;
        background-color: #cecece98;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 0;
    }

    /deep/ .new-chat-dialog {
        background-color: #fefefef5 !important;
    }
}

.chat_area::-webkit-scrollbar {
    // display: none;
    width: 5px;
    height: 5px;
}

.chat_area::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #999999b0;
    -webkit-border-radius: 7px;
    outline: 2px solid #ffffff00;
    outline-offset: 0px;
    cursor: pointer;
    // border: 2px solid #817575;
}

.chat_sidecar_scroll_container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.chat_sidecar_scroll_container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #999999b0;
    -webkit-border-radius: 7px;
    outline: 2px solid #ffffff00;
    outline-offset: 0px;
    border: 2px solid #817575;
}

.chat_sidecar_scroll_container::-webkit-scrollbar-track-piece {
    -webkit-border-radius: 3px;
}

::v-deep .plan-container .el-dialog__title {
    font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
}

::v-deep .plan-container .el-dialog__footer {
    margin-top: -25px;
    padding: 0 20px 20px;
}

@media (max-width: 1228px) {
    .chat_page .chat_sidecar_arrow_container {
        display: none;
    }

    .chat_page .chat_sidecar {
        display: none;
    }

    .chat_page .chat_area_container {
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }

    .chat_page .chat_area_container .chat-area-title-sidecar {
        display: block;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100%) rotateY(-90deg);
        /* 增加旋转的初始角度 */
    }

    to {
        opacity: 1;
        transform: translateX(0) rotateY(0deg);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0) rotateY(0deg);
        /* 增加旋转的初始角度 */
    }

    to {
        opacity: 0;
        transform: translateX(-100%) rotateY(-90deg);
    }
}

@keyframes rotateLeft {
    from {
        opacity: 0;
        transform: rotateY(0deg);
    }

    to {
        opacity: 1;
        transform: rotateY(180deg);
    }
}

@keyframes rotateRight {
    from {
        opacity: 0;
        transform: rotateY(180deg);
    }

    to {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@keyframes hidePlans {
    from {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
    }

    to {
        opacity: 0;
        z-index: -100;
        // transform: translateY(111px);
        transform: translateY(61px);
    }
}

@keyframes topPlans {
    from {
        opacity: 1;
        z-index: 1;
        // transform: translateY(111px);
        transform: translateY(0px);
    }

    to {
        opacity: 1;
        z-index: 1;
        transform: translateY(-61px);
    }
}

@keyframes displayQuit {
    from {
        opacity: 0;
        z-index: -100;
        transform: translateY(61px);
    }

    to {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
    }
}

@keyframes displayPlans {
    from {
        opacity: 1;
        z-index: -100;
        // transform: translateY(111px);
        transform: translateY(-61px);
    }

    to {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
    }
}

@keyframes hideSettings {
    from {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
    }

    to {
        opacity: 0;
        z-index: -100;
        transform: translateY(61px);
    }
}

@keyframes displaySettings {
    from {
        opacity: 0;
        z-index: -100;
        transform: translateY(61px);
    }

    to {
        opacity: 1;
        z-index: 1;
        transform: translateY(0px);
    }
}

@keyframes chatAreaCollapse {
    from {
        left: 50%;
        transform: translate(-50%, 0);
    }

    to {
        left: 420px;
    }
}

@keyframes chatAreaExpand {
    from {
        left: 420px;
    }

    to {
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.animateIn {
    animation: slideIn 1s forwards;
}

.animateOut {
    animation: slideOut 1s forwards;
}

.animateRotateLeft {
    animation: rotateLeft 1s forwards;
}

.animateRotateRight {
    animation: rotateRight 1s forwards;
}

.animateHidePlans {
    animation: hidePlans 0.5s forwards;
}

.animateTopPlans {
    animation: topPlans 0.5s forwards;
}

.animateDisplayPlans {
    animation: displayPlans 0.5s forwards;
}

.animateDisplayQuit {
    animation: displayQuit 0.5s forwards;
}

.animateHideSettings {
    animation: hideSettings 0.5s forwards;
}

.animateDisplaySettings {
    animation: displaySettings 0.4s forwards;
}

.animateChatAreaCollapse {
    animation: chatAreaCollapse 1s forwards;
}

.animateChatAreaExpand {
    animation: chatAreaExpand 1s forwards;
}

.beian {
    position: fixed;
    bottom: 10px;
    width: 100%;
    text-align: center;
}

.beian a {
    color: #666;
    text-decoration: none;
    font-size: 12px;
}

.beian a:hover {
    color: #333;
}
</style>