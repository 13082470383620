<template>
    <div class="content" v-if="showRegister">
        <div class="introduce">
            <div class="introduce-content">
                <p class="tips animate__animated animate__slideInLeft">
                    Welcome to the world of Earthtail AI
                </p>
            </div>
        </div>

        <!-- 登录页面 -->
        <div class="form-wrapper animate__animated animate__slideInRight" v-show="showLoginPage">
            <div class="login-form">
                <h1>ETAI</h1>
                <span class="login-tips">
                    Opportunities are always for those who are prepared.
                </span>
                <!-- <div class="other-login">
                    <img src="../assets/WeChat.png" alt="">
                    <span> 微信登录</span>
                </div> -->
                <div class="divider">
                    <span class="line"></span>
                    <span class="divider-text">登录账号</span>
                    <span class="line"></span>
                </div>
                <div class="form">
                    <div class="input-wrapper">
                        <span class="input-tips">手机号</span>
                        <input v-model="loginForm.phone" type="text" class="ipt" placeholder=""
                            @blur="handleLoginPhoneBlur">
                    </div>
                    <div class="input-wrapper" v-if="loginForm.loginType == 'ETAI_PHONE_PASSWORD'">
                        <span class="input-tips">密码</span>
                        <div class="input-passowrd-wrapper">
                            <input v-model="loginForm.password" :type="showPassword ? 'text' : 'password'" class="ipt"
                                placeholder="" @blur="handleLoginPassordBlur" v-on:keyup.enter="handleLogin">
                            <span class="el-input__suffix">
                                <span class="el-input__suffix-inner">
                                    <i :class="showPasswordIcon" class="cursor-pointer" @click="handleShowOrDisplayPassword"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="input-wrapper" v-if="loginForm.loginType == 'ETAI_PHONE_SMS_CODE'">
                        <span class="input-tips">验证码</span>
                        <input v-model="loginForm.smsCode" type="text" class="ipt" :disabled="disableInputSmsCode"
                            placeholder="" @blur="handleLoginSmscodeBlur" v-on:keyup.enter="handleLogin">
                    </div>
                    <div class="input-wrapper" v-if="loginForm.loginType == 'ETAI_PHONE_SMS_CODE'">
                        <span class="input-tips">图形验证码</span>
                        <div class="captcha-input-wrapper">
                            <input v-model="captcha.code" type="text" class="ipt" placeholder=""
                                @blur="handleLoginCaptchaInputBlur" :disabled="captcha.disabled">
                            <img v-show="showCaptchaCheckedImage & captchaChecked" class="check-img" src="../assets/images/check.png">
                            <img v-show="showCaptchaCheckedImage & !captchaChecked" class="check-img" src="../assets/images/false.png">
                            <img class="cursor-pointer" :src="captchaImageUrl" @click="reloadCaptcha" />
                            <span>点击图片刷新</span>
                        </div>
                    </div>
                    <div class="other-setting">
                        <div class="rem-pwd">
                            <span @click="handleLoginTypeChange">{{ loginForm.loginTypeText }}</span>
                        </div>

                        <span class="forget-pwd" v-if="loginForm.loginType == 'ETAI_PHONE_PASSWORD'">
                            <a @click="handleToForgetPassword">忘记密码？</a>
                        </span>

                        <span class="forget-pwd" v-if="loginForm.loginType == 'ETAI_PHONE_SMS_CODE'">
                            <a @click="handleLoginSendSmscode" :disabled="smsCodeSended">{{ smsCodeSended ?
                                `${sendSmsCodeInterval}s` : '发送短信验证码' }}</a>
                        </span>
                    </div>
                    <button class="btn" @click="handleLogin">登录</button>
                </div>
                <span class="register" @click="handleToRegister">没有账号? 注册</span>
            </div>
        </div>

        <!-- 注册页面 -->
        <div class="form-wrapper animate__animated animate__slideInRight" v-show="showRegisterPage">
            <div class="login-form">
                <h1>ETAI</h1>
                <span class="login-tips">
                    Embrace AI, embrace the infinite possibilities of the future.
                </span>
                <div class="divider">
                    <span class="line"></span>
                    <span class="divider-text">注册账号</span>
                    <span class="line"></span>
                </div>
                <div class="form">
                    <div class="input-wrapper">
                        <span class="input-tips">昵称</span>
                        <input v-model="registerForm.nickName" type="text" class="ipt" placeholder=""
                            @blur="handleRegisterNickNameBlur">
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">手机号</span>
                        <input v-model="registerForm.phone" type="text" class="ipt" placeholder=""
                            @blur="handleRegisterPhoneBlur">
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">密码</span>
                        <div class="input-passowrd-wrapper">
                            <input v-model="registerForm.password" :type="showPassword ? 'text' : 'password'" class="ipt"
                                placeholder="" @blur="handleRegisterPasswordBlur">
                            <span class="el-input__suffix">
                                <span class="el-input__suffix-inner">
                                    <i :class="showPasswordIcon" class="cursor-pointer" @click="handleShowOrDisplayPassword"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">验证码</span>
                        <input v-model="registerForm.smsCode" type="text" class="ipt" placeholder=""
                            @blur="handleRegisterSmscodeBlur" :disabled="disableInputSmsCode"
                            v-on:keyup.enter="handleRegister">
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">图形验证码</span>
                        <div class="captcha-input-wrapper">
                            <input v-model="captcha.code" type="text" class="ipt" placeholder=""
                                @blur="handleRegisterCaptchaInputBlur" :disabled="captcha.disabled">
                            <img v-show="showCaptchaCheckedImage & captchaChecked" class="check-img" src="../assets/images/check.png">
                            <img v-show="showCaptchaCheckedImage & !captchaChecked" class="check-img" src="../assets/images/false.png">
                            <img class="cursor-pointer" :src="captchaImageUrl" @click="reloadCaptcha" />
                            <span>点击图片刷新</span>
                        </div>
                    </div>
                    <div class="other-setting">
                        <div class="rem-pwd">
                        </div>

                        <span class="forget-pwd">
                            <a @click="handleRegisterSendSmscode" :disabled="smsCodeSended">{{ smsCodeSended ?
                                `${sendSmsCodeInterval}s` : '发送短信验证码' }}</a>
                        </span>
                    </div>
                    <button class="btn" style="margin: 10px 0;" @click="handleRegister">注册</button>
                </div>
                <span class="register" @click="handleToLogin">去登录</span>
            </div>
        </div>


        <!-- 重置密码 -->
        <div class="form-wrapper animate__animated animate__slideInRight" v-show="showResetPasswordPage">
            <div class="login-form">
                <h1>ETAI</h1>
                <span class="login-tips">
                    To persevere is to win.
                </span>
                <div class="divider">
                    <span class="line"></span>
                    <span class="divider-text">找回密码</span>
                    <span class="line"></span>
                </div>
                <div class="form">
                    <div class="input-wrapper">
                        <span class="input-tips">手机号</span>
                        <input v-model="resetPasswordForm.phone" type="text" class="ipt" placeholder=""
                            @blur="handleResetPasswordPhoneBlur">
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">新密码</span>
                        <div class="input-passowrd-wrapper">
                            <input v-model="resetPasswordForm.password" :type="showPassword ? 'text' : 'password'"
                                class="ipt" placeholder="" @blur="handleResetPasswordPasswordBlur">
                            <span class="el-input__suffix">
                                <span class="el-input__suffix-inner">
                                    <i :class="showPasswordIcon" class="cursor-pointer" @click="handleShowOrDisplayPassword"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">验证码</span>
                        <input v-model="resetPasswordForm.smsCode" type="text" class="ipt" placeholder=""
                            @blur="handleResetPasswordSmscodeBlur" :disabled="disableInputSmsCode"
                            v-on:keyup.enter="handleResetPassword">
                    </div>
                    <div class="input-wrapper">
                        <span class="input-tips">图形验证码</span>
                        <div class="captcha-input-wrapper">
                            <input v-model="captcha.code" type="text" class="ipt" placeholder=""
                                @blur="handleResetPasswordCaptchaInputBlur" :disabled="captcha.disabled">
                            <img v-show="showCaptchaCheckedImage & captchaChecked" class="check-img" src="../assets/images/check.png">
                            <img v-show="showCaptchaCheckedImage & !captchaChecked" class="check-img" src="../assets/images/false.png">
                            <img class="cursor-pointer" :src="captchaImageUrl" @click="reloadCaptcha" />
                            <span>点击图片刷新</span>
                        </div>
                    </div>
                    <div class="other-setting">
                        <div class="rem-pwd">
                        </div>

                        <span class="forget-pwd">
                            <a @click="handleResetPasswordSendSmsCode" :disabled="smsCodeSended">{{ smsCodeSended ?
                                `${sendSmsCodeInterval}s` : '发送短信验证码' }}</a>
                        </span>
                    </div>
                    <button class="btn" @click="handleResetPassword">重置密码</button>
                </div>
                <span class="register" @click="handleToLogin">去登录</span>
            </div>
        </div>


        <el-dialog title="请输入图片中的验证码" custom-class="global-dialog" :visible.sync="showCaptchaDialog">
            <div class="captcha-container" v-if="showCaptchaDialog">
                <div class="captcha-img">
                    <img :src="captchaImageUrl" @click="reloadCaptcha" />
                    <span>点击图片刷新</span>
                </div>
                <el-input v-model="captcha.code" placeholder="请输入图片中的验证码" @keyup.enter.native="submitCaptchaId">
                    <el-button slot="append" @click="submitCaptchaId" icon="el-icon-s-promotion"></el-button>
                </el-input>
            </div>
        </el-dialog>

        <div class="beian">
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023023096号-1</a>
        </div>
    </div>
</template>
  
<script>

import { _getCaptchaId, _reloadCaptcha, _submitCaptchaId, _getSmsCode, _login, _register, _resetPassword } from '@/services/chatgpt/chatgpt'
import { formatCurrentTime } from '@/utils/formatTime'

export default {
    data() {
        return {
            showLoginPage: true,
            showRegisterPage: false,
            showPassword: false,
            showPasswordIcon: 'el-icon-view',
            showResetPasswordPage: false,
            showCaptchaCodeInput: false,
            showCaptchaCheckedImage: false,
            captchaChecked: false,
            disableInputSmsCode: true,

            isShowDrag: true,
            showLogin: false,
            showRegister: true,
            showLoginPassword: false,
            showThirdParty: true,
            showWeixinThirdParty: false,
            showZhiFuBaoThirdParty: false,
            showAppleThirdParty: false,
            showRetrievePassword: false,
            smsCodeSended: false,
            sendSmsCodeInterval: 60,
            currentTime: formatCurrentTime(new Date),
            captcha: {
                id: '',
                code: '',
                sessionId: '',
                disabled: false
            },
            showCaptchaDialog: false,
            smsCodeSessionId: '',

            resetPasswordForm: {
                phone: '',
                password: '',
                smsCode: '',
            },

            registerForm: {
                nickName: '',
                phone: '',
                password: '',
                smsCode: '',
            },

            loginForm: {
                phone: '',
                password: '',
                smsCode: '',
                loginType: 'ETAI_PHONE_PASSWORD', // ETAI_PHONE_SMS_CODE
                loginTypeText: '验证码登录'
            },

            logined: false,
            enableInputSmsCode: true,
            captchaImageUrl: '/earthtail/api/v1/earthtails/infras/captchas/images/oII3hsJXEpqlrLEyClrj.png?1709097913577',
            captchaImageUrlPrefix: '/earthtail/api/v1/earthtails/infras/captchas/images/',
            captchaImageUrlSuffix: '.png',
            validRuls: {
                phoneRegExp: /^(13[0-9]|14[01456789]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
                passwordRegExp: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,36}$/,
                smscodeRegExp: /^\d{6}$/,
            },
            rules: {
                username: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^(13[0-9]|14[01456789]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入正确的六位短信验证码', trigger: 'blur' }
                ],
            }
        }
    },
    directives: {
        drag: {
            // 指令的定义
            inserted: function (el, binding, vnode) {
                let that = this;
                let oDiv = el;  // 获取当前元素
                let pDiv = el.parentNode;
                let rangWidth = pDiv.clientWidth; //父元素宽度，即 移动范围

                oDiv.onmousedown = (e) => {
                    // 算出鼠标相对元素的位置
                    let disX = e.clientX - oDiv.offsetLeft;

                    console.log("oDiv.onmousedown")
                    window.onmousemove = (e) => {
                        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                        let left = e.clientX - disX;
                        if (left < 0) {
                            left = 0
                        }

                        if ((left > rangWidth - oDiv.clientWidth - 10) && !vnode.context.logined) {
                            vnode.context.login()

                            window.onmousemove = null;
                            window.onmouseup = null;

                            oDiv.style.left = '0px';
                            return
                        }

                        oDiv.style.left = left + 'px';
                    };

                    window.onmouseup = (e) => {
                        let left = e.clientX - disX;
                        if (left < rangWidth - oDiv.clientWidth - 10) {
                            left = 0
                            oDiv.style.left = left + 'px';
                        }
                        window.onmousemove = null;
                        window.onmouseup = null;
                    }
                }
            }
        }
    },
    methods: {
        validatePhone(phone) {
            if (phone == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入手机号码',
                    duration: 3000,
                })

                return false
            }

            if (!this.validRuls.phoneRegExp.exec(phone)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的手机号码',
                    duration: 3000,
                })

                return false
            }

            return true
        },
        handleLoginSendSmscode() {
            if (!this.validatePhone(this.loginForm.phone)) {
                return
            }

            if (this.captcha.code == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入图形验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.captchaChecked) {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入正确的图形验证码',
                    duration: 3000,
                })

                return
            }

            this.getSmscode(this.loginForm.phone)
        },
        handleRegisterSendSmscode() {
            if (!this.validatePhone(this.registerForm.phone)) {
                return
            }

            if (this.captcha.code == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入图形验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.captchaChecked) {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入正确的图形验证码',
                    duration: 3000,
                })

                return
            }

            this.getSmscode(this.registerForm.phone)
        },
        handleResetPasswordSendSmsCode() {
            if (!this.validatePhone(this.resetPasswordForm.phone)) {
                return
            }

            if (this.captcha.code == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入图形验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.captchaChecked) {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入正确的图形验证码',
                    duration: 3000,
                })

                return
            }

            this.getSmscode(this.resetPasswordForm.phone)
        },

        handleLoginCaptchaInputBlur() {
            if (!this.validatePhone(this.loginForm.phone)) {
                return
            }

            this.validateCaptchCode()
        },
        handleRegisterCaptchaInputBlur() {
            if (!this.validatePhone(this.registerForm.phone)) {
                return
            }

            this.validateCaptchCode()
        },
        handleResetPasswordCaptchaInputBlur() {
            if (!this.validatePhone(this.resetPasswordForm.phone)) {
                return
            }

            this.validateCaptchCode()
        },
        handleSendSmscode() {
            console.log('handleSendSmscode', this.loginForm)

            let phone = this.loginForm.phone;

            if (this.showLoginPage) {
                console.log('send smscode to login')

                if (this.loginForm.phone == '') {
                    this.$notify({
                        type: 'warning',
                        title: '警告',
                        message: '请输入手机号码',
                        duration: 3000,
                    })
                    return
                }

                if (!this.validRuls.phoneRegExp.exec(this.loginForm.phone)) {
                    this.$notify.error({
                        title: '警告',
                        message: '请输入正确的手机号码',
                        duration: 3000,
                    })
                    return
                }

                phone = this.loginForm.phone
            }


            if (this.showRegisterPage) {
                console.log('send smscode to register')

                if (this.registerForm.phone == '') {
                    this.$notify({
                        type: 'warning',
                        title: '警告',
                        message: '请输入手机号码',
                        duration: 3000,
                    })
                    return
                }

                if (!this.validRuls.phoneRegExp.exec(this.registerForm.phone)) {
                    this.$notify.error({
                        title: '警告',
                        message: '请输入正确的手机号码',
                        duration: 3000,
                    })
                    return
                }

                phone = this.registerForm.phone
            }

            if (this.showResetPasswordPage) {
                console.log('send smscode to reset password')
                if (this.resetPasswordForm.phone == '') {
                    this.$notify({
                        type: 'warning',
                        title: '警告',
                        message: '请输入手机号码',
                        duration: 3000,
                    })
                    return
                }

                if (!this.validRuls.phoneRegExp.exec(this.resetPasswordForm.phone)) {
                    this.$notify.error({
                        title: '警告',
                        message: '请输入正确的手机号码',
                        duration: 3000,
                    })
                    return
                }

                phone = this.resetPasswordForm.phone
            }

            if (this.captcha.code == '') {
                this.getCaptchaId()
                this.showCaptchaDialog = true
            } else {
                this.getSmscode(phone)
            }

        },
        handleToForgetPassword() {
            console.log('handleToForgetPassword')
            this.showResetPasswordPage = true
            this.showLoginPage = false
            this.showRegisterPage = false

            this.resetStatus()
            this.getCaptchaId()
        },
        handleRegister() {
            console.log('handleRegister')
            this.registerUser()
        },
        handleLogin() {
            console.log('handleLogin')
            this.login();
        },
        handleLoginTypeChange() {
            this.loginForm.phone = ''
            this.loginForm.password = ''
            this.loginForm.smsCode = ''

            this.captcha = {
                id: '',
                code: '',
                sessionId: '',
                disabled: false
            }

            if (this.loginForm.loginType == 'ETAI_PHONE_PASSWORD') {
                this.loginForm.loginType = 'ETAI_PHONE_SMS_CODE'
                this.loginForm.loginTypeText = '密码登录'
                this.getCaptchaId()

                return
            }

            if (this.loginForm.loginType == 'ETAI_PHONE_SMS_CODE') {
                this.loginForm.loginType = 'ETAI_PHONE_PASSWORD'
                this.loginForm.loginTypeText = '验证码登录'

                return
            }
        },

        handleLoginPhoneBlur() {
            if (this.loginForm.phone == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入手机号',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.phoneRegExp.exec(this.loginForm.phone)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的手机号',
                    duration: 3000,
                })
            }
        },
        handleLoginPassordBlur() {
            if (this.loginForm.password == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入密码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.passwordRegExp.exec(this.loginForm.password)) {
                this.$notify.error({
                    title: '警告',
                    message: '密码仅支持格式: 至少包含字母、数字、特殊字符, 8-36位',
                    duration: 3000,
                })
            }
        },
        handleLoginSmscodeBlur() {
            if (this.loginForm.smsCode == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入短信验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.smscodeRegExp.exec(this.loginForm.smsCode)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的六位短信验证码',
                    duration: 3000,
                })
            }
        },

        handleRegisterNickNameBlur() {
            if (this.registerForm.nickName == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入用户名',
                    duration: 3000,
                })

                return
            }
        },

        handleRegisterPhoneBlur() {
            if (this.registerForm.phone == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入手机号码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.phoneRegExp.exec(this.registerForm.phone)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的手机号码',
                    duration: 3000,
                })
            }
        },

        handleRegisterPasswordBlur() {
            if (this.registerForm.password == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入密码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.passwordRegExp.exec(this.registerForm.password)) {
                this.$notify.error({
                    title: '警告',
                    message: '密码仅支持格式: 至少包含字母、数字、特殊字符, 8-36位',
                    duration: 3000,
                })
            }
        },

        handleRegisterSmscodeBlur() {
            if (this.registerForm.smsCode == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入短信验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.smscodeRegExp.exec(this.registerForm.smsCode)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的六位短信验证码',
                    duration: 3000,
                })
            }
        },


        handleResetPasswordPhoneBlur() {
            if (this.resetPasswordForm.phone == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入手机号',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.phoneRegExp.exec(this.resetPasswordForm.phone)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的手机号',
                    duration: 3000,
                })
            }
        },
        handleResetPasswordPasswordBlur() {
            if (this.resetPasswordForm.password == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入新密码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.passwordRegExp.exec(this.resetPasswordForm.password)) {
                this.$notify.error({
                    title: '警告',
                    message: '密码仅支持格式: 至少包含字母、数字、特殊字符, 8-36位',
                    duration: 3000,
                })
            }
        },
        handleResetPasswordSmscodeBlur() {
            if (this.resetPasswordForm.smsCode == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入短信验证码',
                    duration: 3000,
                })

                return
            }

            if (!this.validRuls.smscodeRegExp.exec(this.resetPasswordForm.smsCode)) {
                this.$notify.error({
                    title: '警告',
                    message: '请输入正确的六位短信验证码',
                    duration: 3000,
                })
            }
        },

        handleResetPassword() {
            console.log('handleResetPassword')
            this.resetPassword()
        },

        handleShowOrDisplayPassword() {
            this.showPassword = !this.showPassword

            if (this.showPassword) {
                this.showPasswordIcon = 'el-icon-more'
            } else {
                this.showPasswordIcon = 'el-icon-view'
            }
        },

        handleToRegister() {
            this.showRegisterPage = true
            this.showLoginPage = false
            this.showResetPasswordPage = false

            this.getCaptchaId()
            this.resetStatus()

        },

        handleToLogin() {
            this.showLoginPage = true
            this.showRegisterPage = false
            this.showResetPasswordPage = false

            this.resetStatus()
        },

        resetStatus() {
            this.loginForm = {
                phone: '',
                password: '',
                smsCode: '',
                loginType: 'ETAI_PHONE_PASSWORD', // ETAI_PHONE_SMS_CODE
                loginTypeText: '验证码登录'
            }
            this.resetPasswordForm = {
                phone: '',
                password: '',
                smsCode: '',
            }

            this.registerForm = {
                nickName: '',
                phone: '',
                password: '',
                smsCode: '',
            }

            this.captcha = {
                id: '',
                code: '',
                sessionId: '',
                disabled: false
            }

            this.showPassword = false
            this.showPasswordIcon = 'el-icon-view'
            this.showCaptchaCodeInput= false
            this.showCaptchaCheckedImage= false
            this.captchaChecked= false
            this.disableInputSmsCode= true
        },


        weixinLogin() {
            console.log('weixin')
        },
        zhifubaoLogin() {
            console.log('zhifubao')
        },
        appleLogin() {
            console.log('apple')
        },

        async registerUser() {
            let param = {
                nickName: this.registerForm.nickName,
                phone: this.registerForm.phone,
                password: this.registerForm.password,
                smsCode: this.registerForm.smsCode,
                smsSessionuuid: this.smsCodeSessionId,
            }

            let res = await _register(param)
            console.log(res)
            if (res.success) {
                console.log('register success')
                this.$notify({
                    type: 'success',
                    message: res.message,
                    duration: 3000,
                });

                this.handleToLogin();

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        async login() {
            if (this.logined) {
                console.log('this.logined', this.logined)
                return
            }

            let param = {
                loginType: this.loginForm.loginType,
                phone: this.loginForm.phone,
                password: this.loginForm.password,
                smsCode: this.loginForm.smsCode,
                smsSessionuuid: this.smsCodeSessionId,
                validCookie: false,
            }

            let res = await _login(param)
            console.log(res)
            if (res.success) {
                console.log('login success')
                this.logined = true

                this.$router.push({
                    path: '/chat'
                }).catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },
        async validCookie() {
            if (this.logined) {
                return
            }

            let param = {
                loginType: "ETAI_PHONE_SMS_CODE",
                phone: this.loginForm.phone,
                smsCode: this.loginForm.smsCode,
                smsSessionuuid: this.smsCodeSessionId,
                validCookie: true,
            }

            let res = await _login(param)
            console.log(res)
            if (res.success) {
                if (res.code == 1000200) {
                    console.log('login success')
                    this.logined = true

                    this.$router.push({
                        path: '/chat'
                    }).catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }
        },

        async resetPassword() {
            let param = {
                phone: this.resetPasswordForm.phone,
                password: this.resetPasswordForm.password,
                smsCode: this.resetPasswordForm.smsCode,
                smsSessionuuid: this.smsCodeSessionId,
                validCookie: false,
            }

            let res = await _resetPassword(param)
            console.log(res)
            if (res.success) {
                console.log('reset password success')

                this.handleToLogin()

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },
        async getCaptchaId() {
            console.log('getCaptchaId')
            let res = await _getCaptchaId();
            console.log(res)
            if (res.success) {
                this.captcha.id = res.result[0]
                this.captchaImageUrl = this.complateCaptachImageUrl();
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },
        async reloadCaptcha() {
            let param = {
                uid: this.captcha.id
            }

            let res = await _reloadCaptcha(param);
            console.log(res)
            if (res.success) {
                this.captcha.id = res.result[0].uid;
                this.captchaImageUrl = this.complateCaptachImageUrl();
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }

        },
        async validateCaptchCode() {
            let param = {
                captchaId: this.captcha.id,
                code: this.captcha.code
            }

            let res = await _submitCaptchaId(param)
            console.log(res)
            if (res.success) {
                this.captcha.sessionId = res.result[0].sessionuuid
                this.captchaChecked = true
                this.showCaptchaCheckedImage = true

            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });

                this.captchaChecked = false
                this.showCaptchaCheckedImage = true

                this.getCaptchaId()
            }
        },
        async submitCaptchaId() {
            let param = {
                captchaId: this.captcha.id,
                code: this.captcha.code
            }

            let res = await _submitCaptchaId(param)
            console.log(res)
            if (res.success) {
                this.captcha.sessionId = res.result[0].sessionuuid
                this.showCaptchaDialog = false
                this.enableInputSmsCode = false
                this.handleSendSmscode()
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });

                this.getCaptchaId()
            }
        },
        async getSMSCode() {
            console.log('getSMSCode')
            if (this.loginForm.phone == '') {
                this.$notify({
                    type: 'warning',
                    title: '警告',
                    message: '请输入手机号码',
                    duration: 3000,
                })
                return
            }

            if (this.captcha.code == '') {
                this.getCaptchaId()
                this.showCaptchaDialog = true
            } else {
                this.getSmscode(this.loginForm.phone)
            }
        },

        async getSmscode(phone) {
            console.log(this.captcha)
            let param = {
                phone: phone,
                sessionuuid: this.captcha.sessionId,
                captchaId: this.captcha.id,
                captchaCode: this.captcha.code
            }

            let res = await _getSmsCode(param)
            console.log(res)
            if (res.success) {
                this.captcha.disabled = true

                this.smsCodeSended = true
                this.disableInputSmsCode = false
                this.smsCodeSessionId = res.result[0].sessionuuid
                this.sendSmsTimer = setInterval(() => {
                    this.sendSmsCodeInterval--
                    if (this.sendSmsCodeInterval == 0) {
                        clearInterval(this.sendSmsTimer);
                        this.smsCodeSended = false
                        this.sendSmsCodeInterval = 60
                    }
                }, 1000)
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },
        complateCaptachImageUrl() {
            return this.captchaImageUrlPrefix + this.captcha.id + this.captchaImageUrlSuffix + '?' + Date.now();
        }
    },
    mounted() {
        this.validCookie();
    },
    beforeDestroy() {
        clearInterval(this.sendSmsTimer);
    }

}
</script>
  
<style lang="less" scoped>
@charset "UTF-8";

@font-face {
    font-family: Butler_Light;
    font-weight: 700;
    src: url(../assets/font/Butler_Light.otf) format("truetype");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Butler_Black;
    font-weight: 700;
    src: url(../assets/font/Butler_Black.otf) format("truetype");
    text-rendering: optimizeLegibility;
}

* {
    padding: 0;
    margin: 0;
    font-family: Butler_Light;
}

.content {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    overflow: hidden;

    background-image: url(../assets/images/bg.jpg);
    background-attachment: fixed;
    background-size: cover;
    color: #fff;
}

.content .cursor-pointer {
    cursor: pointer;
}

.content .introduce {
    width: 60%;
    height: 100%;
    position: relative;
    position: relative;
}

.content .introduce .introduce-content .tips {
    margin: 20px 0;
    font-size: 25px;
    position: absolute;
    left: 20%;
    top: 40%;
    transform: translateY(-50%);
    font-family: Butler_Black;
}

.content .form-wrapper {
    margin-right: 5%;
    border-radius: 34px;
    width: 40%;
    height: 90%;
    position: absolute;
    right: 0;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.603);
    /* 添加透明阴影 */
}

.content .form-wrapper .login-form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.content .form-wrapper .login-form h1 {
    font-size: 55px;
    margin-bottom: 20px;
}

.content .form-wrapper .login-form .login-tips {
    font-size: 20px;
}

.content .form-wrapper .login-form .other-login {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 1px solid #fff;
    text-align: center;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 50px 0 10px 0;
    cursor: pointer;
    transition: 0.2s;
}

.content .form-wrapper .login-form .other-login:hover {
    border: 1px solid #9faff8;
}

.content .form-wrapper .login-form .other-login img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.content .form-wrapper .login-form .other-login span {
    vertical-align: middle;
}

.content .form-wrapper .login-form .divider {
    width: 100%;
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content .form-wrapper .login-form .divider .line {
    display: inline-block;
    flex: 1;
    height: 1px;
    background-color: #fff;
}

.content .form-wrapper .login-form .divider .divider-text {
    vertical-align: middle;
    margin: 0 20px;
    display: inline-block;
    color: #fff;
    white-space: normal;
}

.content .form-wrapper .login-form .form .input-wrapper {
    width: 100%;
    height: 40px;
    color: #fff;
    margin: 20px 0 50px 0;
}



.content .form-wrapper .login-form .form .input-wrapper span {
    display: inline-block;
    margin-bottom: 5px;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #fff;
    padding: 10px 30px 10px 30px;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    color: #fff;
    font-size: 20px;
    transition: 0.2s;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt:focus {
    border: 1px solid #9faff8;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt::-webkit-input-placeholder {
    /* 使用webkit内核的浏览器 */
    color: #fff;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt:-moz-placeholder {
    /* Firefox版本4-18 */
    color: #fff;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt::-moz-placeholder {
    /* Firefox版本19+ */
    color: #fff;
}

.content .form-wrapper .login-form .form .input-wrapper .ipt:-ms-input-placeholder {
    /* IE浏览器 */
    color: #fff;
}

.content .form-wrapper .login-form .form .input-wrapper .input-passowrd-wrapper {
    position: relative;
    height: 100%;
    line-height: 40px;
}

.content .form-wrapper .login-form .form .input-wrapper .input-passowrd-wrapper i {
    position: relative;
    margin-right: 10px;
}

.content .form-wrapper .login-form .form .input-wrapper .captcha-input-wrapper {
    height: 40px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content .form-wrapper .login-form .form .input-wrapper .captcha-input-wrapper .ipt {
    margin-right: 5px;
}

.content .form-wrapper .login-form .form .input-wrapper .captcha-input-wrapper .check-img {
    background-color: #ffffff00;
    width: 20px;
    height: 20px;
    align-self: center;
    margin-right: 20px;
}

.content .form-wrapper .login-form .form .input-wrapper .captcha-input-wrapper img {
    background-color: #fff;
    margin-right: 20px;
}

.content .form-wrapper .login-form .form .input-wrapper .captcha-input-wrapper span {
    width: 360px;
    font-size: 12px;
    align-self: flex-end;
}

.content .form-wrapper .login-form .form .other-setting {
    display: flex;
    justify-content: space-between;
}

.content .form-wrapper .login-form .form .other-setting .rem-pwd {
    cursor: pointer;
}

.content .form-wrapper .login-form .form .other-setting .forget-pwd {
    cursor: pointer;
}

.content .form-wrapper .login-form .form .btn {
    width: 100%;
    height: 50px;
    border: 0;
    background-color: #fff;
    border-radius: 5px;
    color: #000;
    text-align: center;
    margin: 50px 0;
    font-size: 20px;
    cursor: pointer;
}

.content .form-wrapper .login-form .register {
    color: #fff;
    cursor: pointer;
}

.content .captcha-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content .captcha-container .captcha-img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px;

    img {
        background-color: #fff;
        margin-right: 20px;
    }
}

@media (max-width: 768px) {
    .content .introduce {
        display: none;
    }

    .content .form-wrapper {
        width: 100%;
    }

    .content .form-wrapper .login-form .divider {
        margin: 10px 0;
    }

    .content .form-wrapper .login-form .other-login {
        margin: 10px 0;
    }

    .content .form-wrapper .login-form .form .btn {
        margin: 20px 0;
    }
}

.beian {
    position: fixed;
    bottom: 10px;
    width: 100%;
    text-align: center;
    z-index: 999;
    
    a {
        color: #fff;
        text-decoration: none;
        font-size: 12px;
        
        &:hover {
            color: #9faff8;
        }
    }
}
</style>
  